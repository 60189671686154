export class AppConstants{
    public static readonly rtcAppName = 'RunTheCloud';

    public static readonly rtcTableIndexUrl = '/search?api-version=2023-11-01';
    public static readonly tenantItemsTableIndex = 'Run the Cloud Dashboard';

    public static readonly tenantTableUrl = `/messagestatus`;

    public static readonly licenseCheckUrl = `/haslicense`;

    public static readonly baseApiUrl = `https://api.coffeenet365.com/rtc/api`;

    public static readonly scopes = ['https://graph.microsoft.com/Sites.Manage.All', 'https://graph.microsoft.com/Group.Read.All', 'https://graph.microsoft.com/User.Read', 'https://graph.microsoft.com/Tasks.ReadWrite', 'https://graph.microsoft.com/Channel.ReadBasic.All', 'https://graph.microsoft.com/ChannelMessage.Send', 'https://graph.microsoft.com/TeamsAppInstallation.ReadForTeam', 'https://graph.microsoft.com/TeamsTab.Read.All', 'https://graph.microsoft.com/ChannelMember.Read.All', 'https://graph.microsoft.com/TeamworkTag.Read', 'https://graph.microsoft.com/Sites.ReadWrite.All', 'https://graph.microsoft.com/openid', 'https://graph.microsoft.com/profile'];

    public static readonly simplifiedScopes = ['Sites.Manage.All', 'Group.Read.All', 'User.Read', 'Tasks.ReadWrite', 'Channel.ReadBasic.All', 'ChannelMessage.Send', 'TeamsAppInstallation.ReadForTeam', 'TeamsTab.Read.All', 'ChannelMember.Read.All', 'TeamworkTag.Read', 'Sites.ReadWrite.All', 'openid', 'profile'];

    public static readonly sharePointScopes = ['https://microsoft.sharepoint.com/AllSites.Read', 'https://microsoft.sharepoint.com/AllSites.Write'];

    public static readonly simplifiedSharePointScopes = ['AllSites.Read', 'AllSites.Write'];

    public static readonly runthecloudemail = 'runthecloud@mondaycoffee.com';
  }