import * as React from 'react';
import { ILicenseStatus, LicenseType } from '../../common/interfaces/ILicenseStatus';
import { MessageItem } from '../../common/interfaces/IMessageItem';
import { StackShim } from '@fluentui/react-migration-v8-v9';
import { Divider, Label, makeStyles, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, Tooltip } from '@fluentui/react-components';
import { Archive20Regular, ChevronDown20Regular, ChevronUp20Regular, Link20Regular, MoreHorizontal20Regular, Open20Regular } from '@fluentui/react-icons';
import { Icon } from '@fluentui/react/lib/Icon';
import { sanitize } from 'dompurify';
import { ReactHelper } from '../../common/helpers/ReactHelper';
import { Theme as ThemeV9 } from '@fluentui/react-components';
import { IRTCContext } from '../RTCWrapper/RTCContext';

export interface IMessageCardProps {
    message: MessageItem;
    index: number;
    license: ILicenseStatus;
    themeV9: ThemeV9;
    rtcContext: IRTCContext;
    expandCollapseCard: (index: number, expand: boolean) => void;
    archiveFromMenu: (messageId: string) => void;
    shareToTeamsFromMenu: (messageId: string) => void;
    copyLinkFromMenu: (messageId: string) => void;
    openMessage: (message: MessageItem) => void;
}

const messageStyles = makeStyles({
    badgeLabelStyle: {
        ...shorthands.padding('12px, 10px, 12px, 10px'), 
        color: '#BDBDBD', 
        display: 'block', 
        textAlign: 'center', 
        width: '75px', 
        marginBottom: '5px'
    },
    mainMessageBody: {             
        display: 'inline-flex',
        width: '98.5%',
        ...shorthands.padding('16px'),
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.25)',
        ...shorthands.borderTop('1px solid #F8F8F8'),
        ...shorthands.borderRight('1px solid #F8F8F8'),
        ...shorthands.borderBottom('1px solid #F8F8F8')        
    },
    drawerTitle: {
        '> h2': {
            width: '85% !important'
        }      
    },
    whiteGlove: {
        ':hover': {
            cursor: 'pointer'
        }
    }
});

export const MessageCard: React.FunctionComponent<IMessageCardProps> = (props) => {
    const styles = messageStyles();

    const titleWidth = (props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial) ? 400 : 300;

    return(
        /*<div style={{display: 'flex', position: 'relative', left: props.message.isRead ? 0 : -5, marginTop: 20, width: props.message.isRead ? '100%' : 'calc(100% + 5px)'}}>*/
        <div style={{display: 'flex', position: 'relative', left: props.message.isRead ? 0 : -5, marginTop: 20, width: 'calc(100% + 5px)'}}>
            {!props.message.isRead && <div style={{backgroundColor: props.rtcContext.themeV9.colorCompoundBrandStroke, width: 5}} />}
            <div key={props.message.messageId} className={styles.mainMessageBody}>                    
                <div style={{width: '100%'}}>
                    <div style={{display: 'grid'}}>
                        <StackShim horizontal>
                            <div style={{width: `calc(100% - ${titleWidth}px)`}}>
                                <Label style={{fontSize: 20, fontWeight: 600}} className={styles.whiteGlove} onClick={() => props.openMessage(props.message)}>{`${props.message.messageId} | ${props.message.messageRTCTitle}`}</Label>
                                <div style={{alignItems: 'baseline', marginTop: 6}}>
                                    <Label>{`${props.rtcContext.localization.WebParts.Dashboard.publishedOn} ${props.message.messageDatePublished} | `}</Label>
                                    {(props.message.messageServices && props.message.messageServices.length > 0) && 
                                    <Label style={{marginTop: 11}}>{ReactHelper.renderServicesString(props.message)}</Label>
                                }
                                </div>                                
                            </div>
                            <div style={{display: 'inline-flex', width: titleWidth}}>
                                <div style={{marginRight: 25}}>
                                    <Label className={styles.badgeLabelStyle}>{props.rtcContext.localization.WebParts.Dashboard.itAdmin}</Label>
                                    {ReactHelper.renderBadge(props.message.messageITAdminAction, props.rtcContext.localization)}
                                </div>
                                <div style={{marginRight: 25}}>
                                    <Label className={styles.badgeLabelStyle}>{props.rtcContext.localization.WebParts.Dashboard.appOwner}</Label>
                                    {ReactHelper.renderBadge(props.message.messageAppOwnerAction, props.rtcContext.localization)}
                                </div>
                                <div style={{marginRight: 25}}>
                                    <Label className={styles.badgeLabelStyle}>{props.rtcContext.localization.WebParts.Dashboard.security}</Label>
                                    {ReactHelper.renderBadge(props.message.messageSecurityAction, props.rtcContext.localization)}
                                </div>
                                {(props.license.Type === LicenseType.Compliance || props.license.Type === LicenseType.FreeTrial) && <div>
                                    <Label className={styles.badgeLabelStyle}>{props.rtcContext.localization.WebParts.Dashboard.compliance}</Label>
                                    {ReactHelper.renderBadge(props.message.messageComplianceAction, props.rtcContext.localization)}
                                </div>}
                            </div>
                        </StackShim>
                        <div style={{width: '100%', display: 'grid'}}>
                            {(props.message.cardExpanded && props.message.messageDescription && props.message.messageDescription.length> 0) &&
                            <>
                                <Label style={{fontWeight: 600, marginTop: 29}}>{props.rtcContext.localization.WebParts.MessagePanel.summary}</Label>
                                <div style={{marginTop: 11, display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', width: '99%'}} dangerouslySetInnerHTML={{__html: sanitize(props.message.messageDescription).replace(/<a /gmi, `<a target="_blank" style="color: ${props.themeV9.colorBrandForegroundLink};"`)}} />
                            </>}                            
                        </div>                              
                    </div> 
                </div>
                <Divider vertical appearance='strong' />
                <div style={{width: 15, marginTop: 10, height: 'auto', paddingLeft: 5, position: 'relative'}}>                    
                    {!props.message.cardExpanded && <Tooltip withArrow content={props.rtcContext.localization.CommonStrings.Expand} relationship="label"><ChevronDown20Regular onClick={() => props.expandCollapseCard(props.index, true)} /></Tooltip>}
                    {props.message.cardExpanded && <Tooltip withArrow content={props.rtcContext.localization.CommonStrings.Collapse} relationship="label"><ChevronUp20Regular onClick={() => props.expandCollapseCard(props.index, false)} /></Tooltip>}
                    {props.license.Type !== LicenseType.Basic && <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton icon={<MoreHorizontal20Regular />} appearance='subtle' style={{left: -5, position: 'relative'}} />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <Tooltip withArrow content={props.rtcContext.localization.CommonStrings.Archive} relationship="label"><MenuItem icon={<Archive20Regular />} onClick={() => props.archiveFromMenu(props.message.messageId)}>{props.rtcContext.localization.CommonStrings.Archive}</MenuItem></Tooltip>
                                <Tooltip withArrow content={props.rtcContext.localization.WebParts.Dialogs.shareInTeams} relationship="label"><MenuItem icon={<Icon iconName='TeamsLogo' style={{fontSize: 18}} />} onClick={() => props.shareToTeamsFromMenu(props.message.messageId)}>{props.rtcContext.localization.WebParts.Dialogs.shareInTeams}</MenuItem></Tooltip>
                                <Tooltip withArrow content={props.rtcContext.localization.CommonStrings.CopyLink} relationship="label"><MenuItem icon={<Link20Regular />} onClick={() => props.copyLinkFromMenu(props.message.messageId)}>{props.rtcContext.localization.CommonStrings.CopyLink}</MenuItem></Tooltip>
                            </MenuList>
                        </MenuPopover>
                    </Menu>}
                    {props.license.Type === LicenseType.Basic && <Tooltip withArrow content={props.rtcContext.localization.CommonStrings.Archive} relationship="label"><MenuButton icon={<Archive20Regular />} appearance='subtle' style={{left: -5, position: 'relative'}} onClick={() => props.archiveFromMenu(props.message.messageId)} /></Tooltip>}
                    {props.message.cardExpanded && <MenuButton icon={<Open20Regular />} appearance='subtle'  style={{left: 0, bottom: 0, position: 'absolute'}} onClick={() => props.openMessage(props.message)} />}
                </div>
            </div>
        </div>
    );
}