import {
    FluentProvider,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    Label,
  } from "@fluentui/react-components";
import RTCContext from './RTCWrapper/RTCContext';
import { useContext } from "react";

export interface IMobileProps { }

export const MobileView: React.FunctionComponent<IMobileProps> = (props) => {
    const rtcContext = useContext(RTCContext);

    return (
        <FluentProvider theme={
            rtcContext.themeName === "dark"
              ? teamsDarkTheme
              : rtcContext.themeName === "contrast"
              ? teamsHighContrastTheme
              : {
                  ...teamsLightTheme
                }
          }>
            <div style={{textAlign: 'center', paddingTop: 120}}>
                <Label weight="semibold" style={{fontSize: 24}}>RunTheCloud</Label><br /><br />
                <Label weight="semibold" style={{marginTop: 20}}>{rtcContext.localization.WebParts.Tooltips.mobileSupportSubtitle}</Label><br />
                <Label>{rtcContext.localization.WebParts.Tooltips.mobileSupportDescription}</Label><br />
            </div>
        </FluentProvider>
    );
}