const config = {
  //Values for MCAG, use these values when doing a commit because the pipeline will use them
  initiateLoginEndpoint: 'https://rtcapp.mondaycoffee.com/auth-start.html',
  clientId: '84fa4ea4-fd5d-4d4e-a94b-289a5a222980',
  //Use these values while doing local development
  //initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  //clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.M365_CLIENT_SECRET,
  tenantId: process.env.M365_TENANT_ID,
  authorityHost: process.env.M365_AUTHORITY_HOST,
};

export default config;
