/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@fluentui/react-components";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-components";
import { TeamsFxContext } from "./Context";
import { app, pages } from "@microsoft/teams-js";
import { useContext, useEffect, useState } from "react";
import { ILicenseStatus, LicenseType } from "../common/interfaces/ILicenseStatus";
import { LicenseService } from "../common/services/LicenseService/LicenseService";
import { RtCDashboard } from "./Dashboard/DashboardComponent";
import { RTCWrapper } from "./RTCWrapper/RTCWrapper";
import LocalizationService from "../common/services/LocalizationService/LocalizationService";
import { GetGraphAccessToken, GetUserImpersonationAccessToken } from "../common/services/HttpClientService/HttpClientService";
import axios from "axios";
import HttpHeaderHelper from "../common/helpers/HttpHeadersHelper";
import { Providers } from "@microsoft/mgt-element";
import { CacheService } from "@microsoft/mgt";
import { initializeIcons } from "@fluentui/react";
import { MobileView } from "./Mobile";

export interface ITabProps { }

export const Tab: React.FunctionComponent<ITabProps> = (props) => {
  const [subscriptionKey, setSubscriptionKey] = useState<string>('');
  const [sharedMode, setSharedMode] = useState<boolean>(true);
  const [license, setLicense] = useState<ILicenseStatus>(undefined);
  const [teamsContext, setTeamsContext] = useState<app.Context>(undefined);
  const [groupId, setGroupId] = useState<string>('');
  const [siteUrl, setSiteUrl] = useState<string>('');
  const [siteId, setSiteId] = useState<string>('');
  const [sitePath, setSitePath] = useState<string>('');
  const [upn, setUpn] = useState<string>('');
  const [userDisplayName, setUserDisplayNamen] = useState<string>('');
  const [localization, setLocalization] = useState<IRTCresources>(undefined);
  const [renderTab, setRenderTab] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  const teamsFxContext = useContext(TeamsFxContext);

  const getSiteUrl = async (groupId: string, accessToken: string): Promise<string> => {
    try{
      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      const resp = await axios.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/sites/root`, { headers: reqHeaders });

      if(resp.status === 200){
        const idParts = resp.data.id.split(',');

        setSiteId(idParts[1]);

        const sitePathPart = resp.data.webUrl.toString().toLowerCase().replace(`https://${resp.data.siteCollection.hostname}`, '');

        setSitePath(sitePathPart);

        return resp.data.webUrl;
      }
    }catch(error){
      console.error(error);

      return '';
    }
  };

  const getUser = async (accessToken: string): Promise<string> => {
    try{
      const reqHeaders = HttpHeaderHelper.getHeaders(undefined, false, false, accessToken);

      const resp = await axios.get(`https://graph.microsoft.com/v1.0/me`, { headers: reqHeaders });

      if(resp.status === 200){
        setUserDisplayNamen(resp.data.displayName);

        return resp.data.userPrincipalName;
      }
    }catch(error){
      console.error(error);

      return '';
    }
  };


  const setLocale = async (locale: string): Promise<void> => {
    const loc = await LocalizationService.loadLocale(locale);
    setLocalization(loc);
  }

  const componentDidMount = async () => {
    initializeIcons();

    await app.initialize();

    const pageConfig = await pages.getConfig();

    const context = await app.getContext();

    const render = context.app.host.clientType === 'web' || context.app.host.clientType === 'desktop' || context.app.host.clientType === 'macos';

    setRenderTab(render);

    await setLocale(context.app.locale);

    if(render){
      const cacheId = await Providers.getCacheId();
    
      CacheService.clearCacheById(cacheId);

      setTeamsContext(context);

      setGroupId(context.team.groupId);

      const accessToken = await GetGraphAccessToken(true);

      const siteUrl = await getSiteUrl(context.team.groupId, accessToken);

      setSiteUrl(siteUrl);

      const upnValue = await getUser(accessToken);

      setUpn(upnValue);
      
      const accessTokenUP = await GetUserImpersonationAccessToken();      

      if(pageConfig.entityId){
        const configParts = pageConfig.entityId.split(":");

        if(configParts.length >= 2){
          setSubscriptionKey(configParts[0]);
          setSharedMode(configParts[1] === 'true');

          let freeTrial = false;

          if(configParts.length > 2){
            freeTrial = configParts[2] === 'true';
          }

          let license: ILicenseStatus = undefined;

          if(!freeTrial){
            const licenseService = new LicenseService(accessTokenUP);
            license = await licenseService.getLicenseStatus(configParts[0]);
          }else{
            license = {
              IsValid: true,
              Type: LicenseType.FreeTrial
            };
          }

          setLicense(license);
        }
      }
    }    

    setLoading(false);
  };

  useEffect(() => {    
    componentDidMount();  
  }, []);

  return (
    <TeamsFxContext.Provider value={teamsFxContext}>
      <FluentProvider
        theme={
          teamsFxContext.themeString === "dark"
            ? teamsDarkTheme
            : teamsFxContext.themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme
              }
        }
      >
        {loading && <Spinner style={{marginTop: 40}} />}
        {(!loading && renderTab) && <RTCWrapper siteId={siteId} sitePath={sitePath} groupId={groupId} siteUrl={siteUrl} upn={upn} userDisplayName={userDisplayName} themeV9={teamsFxContext.theme} themeName={teamsFxContext.themeString} teamsContext={teamsContext} localization={localization}><RtCDashboard subscriptionKey={subscriptionKey} sharedMode={sharedMode} license={license} /></RTCWrapper>}
        {(!loading && !renderTab) && <RTCWrapper siteId={siteId} sitePath={sitePath} groupId={groupId} siteUrl={siteUrl} upn={upn} userDisplayName={userDisplayName} themeV9={teamsFxContext.theme} themeName={teamsFxContext.themeString} teamsContext={teamsContext} localization={localization}><MobileView /></RTCWrapper>}
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
};